import React from "react";
import Footer from "./footer";
import Header from "./header";

const Layout = (props) => {
  return (
    <>
      <aside className="bg-gray-50 w-full">
        <aside className="max-w-screen-lg flex flex-col items-center justify-center mx-auto my-auto py-2">
          <div className="text-sm font-light text-gray-500 text-center p-2">
            👋 &nbsp; Hi There, We are in the process of deploying our new
            Website. Some links might not work. Sorry for the Inconvinience
          </div>
        </aside>
      </aside>
      <Header />
      {props.children}

      <Footer />
    </>
  );
};

export default Layout;
